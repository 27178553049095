<template>
    <div>
        <Row class="illustration" type="flex" justify="center">
            <Col :span="22" :md="18" :lg="18">
                <Row class="banner-container">
                    <Col class="block left">
                        <img src="@img/illustration/i-1.jpg" alt="">
                    </Col>
                    <Col class="block right">
                        <img src="@img/illustration/i-2.jpg" alt="">
                    </Col>
                </Row>
            </Col>
            <Col :span="22" :md="18" :lg="18">
                <img src="@img/illustration/i-3.jpg" alt="">
            </Col>
            <Col :span="22" :md="18" :lg="18">
                <img src="@img/illustration/i-10.jpg" alt="">
            </Col>
        </Row>
        <copy-right />
    </div>
</template>

<script>
import common from '@M/common';
import CopyRight from '@C/CopyRight';

export default {
    name: 'Illustration',
    mixins: [common],
    components: {
        CopyRight
    }
}
</script>

<style lang="scss">
.illustration {
    > div {
        
        color: #000;
        font-size: 12px;
        line-height: 2em;

        &:first-child {
            margin-top: 5rem;
        }

        &:last-child {
            margin-bottom: 2rem;
        }
        img {
            width: 100%;
            height: inherit;
        }

        .banner-container {
            --width: calc(50vw);
            height: calc(var(--width));
            display: flex;
            
            .block {
                width: 50%;
                height: 100%;
                > img {
                    width: auto;
                    height: 50%;
                }

                &.left {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;
                    background: #F9F8F3;
                }

                &.right {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    background: #F1F1EF;    
                }
            }
        }
    }
}
</style>
